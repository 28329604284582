<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <b-navbar-nav class="nav  ml-auto  align-items-center flex-grow-1 d-lg-flex">
      <!-- <bookmarks /> -->
      <!-- <dark-Toggler class="d-lg-block" /> -->
    </b-navbar-nav>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-button
        class="btn-warning"
        data-tally-open="3jaJZR"
        data-tally-width="500"
        data-tally-hide-title="1"
        data-tally-emoji-text="✍️"
        data-tally-emoji-animation="wave"
        :data-email="$store.state.user.userData.user_id"
      >
        <feather-icon
          icon="HelpCircleIcon"
          size="22"
        />
      </b-button>
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BButton,
} from 'bootstrap-vue'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BLink,
    BButton,
    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
