<template>
  <layout-vertical>

    <template v-slot:navbar="layoutOriginal">
      <app-navbar-vertical-layout :toggle-vertical-menu-active="layoutOriginal.toggleVerticalMenuActive" />
    </template>

    <!-- <div
      slot="vertical-menu-header"
      class="d-flex align-items-center h-100"
    >
      <h1 class="font-medium-5 mb-0 text-primary">
        Company Name
      </h1>
    </div> -->

    <div
      slot="breadcrumb"
      class="p-1"
    />

    <router-view />

    <div slot="footer">
      <p class="mb-0">
        &copy; KMS Serviços e Soluções Tecnológicas Ltda. {{ new Date().getFullYear() }}
      </p>
    </div>

  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import AppNavbarVerticalLayout from '../components/AppNavbarVerticalLayout.vue'

export default {
  components: {
    LayoutVertical,
    AppNavbarVerticalLayout,
  },
  data() {
    return {
    }
  },
}
</script>
